"use client";
import {
  withPhosphorWrapper
} from "./chunk-LVROC32W.js";

// src/Boat.tsx
import { jsx, jsxs } from "react/jsx-runtime";
var SvgBoat = withPhosphorWrapper((props) => /* @__PURE__ */ jsxs(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    fill: "none",
    viewBox: "0 0 32 32",
    ...props,
    children: [
      /* @__PURE__ */ jsx(
        "path",
        {
          stroke: "currentColor",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 1.5,
          d: "M5.5 16h25s-2.227 8-5.5 8H14"
        }
      ),
      /* @__PURE__ */ jsx(
        "path",
        {
          stroke: "currentColor",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 1.5,
          d: "M24 24H3a1 1 0 0 1-1-1l3-6 3.5-7.5m0 0h11l4 6.5m-15-6.5H6M13.869 16v-5.998"
        }
      )
    ]
  }
));
var Boat_default = SvgBoat;

export {
  Boat_default
};
