"use client";
import {
  withPhosphorWrapper
} from "./chunk-LVROC32W.js";

// src/Motorcycle.tsx
import { jsx, jsxs } from "react/jsx-runtime";
var SvgMotorcycle = withPhosphorWrapper((props) => /* @__PURE__ */ jsxs(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    fill: "none",
    viewBox: "0 0 32 32",
    ...props,
    children: [
      /* @__PURE__ */ jsx(
        "path",
        {
          stroke: "currentColor",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 1.5,
          d: "M21 21.5h-5.5L10 17M26 25a5 5 0 1 0 0-10 5 5 0 0 0 0 10Z"
        }
      ),
      /* @__PURE__ */ jsx(
        "path",
        {
          stroke: "currentColor",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 1.5,
          d: "M6 25a5 5 0 1 0 0-10 5 5 0 0 0 0 10ZM12.5 15s3.105 1.01 5 .5c2.666-.718 3.169-.942 3.5-3 .5-3.11-6.509-1.771-7.509.729M12.5 15H3s-.054-1.079.5-2c1.078-1.793 10-2.056 10 .164 0 .336-1 1.836-1 1.836ZM17 7.5h2l1.4 2.5M26 20l-5.6-10m0 0 3.1-.5 1 2.5-2 1"
        }
      )
    ]
  }
));
var Motorcycle_default = SvgMotorcycle;

export {
  Motorcycle_default
};
