"use client";
import {
  withPhosphorWrapper
} from "./chunk-LVROC32W.js";

// src/City2.tsx
import { jsx, jsxs } from "react/jsx-runtime";
var SvgCity2 = withPhosphorWrapper((props) => /* @__PURE__ */ jsxs(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    fill: "none",
    viewBox: "0 0 32 32",
    ...props,
    children: [
      /* @__PURE__ */ jsx(
        "path",
        {
          stroke: "currentColor",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 1.5,
          d: "M2 26.998h28M19 26.998v-22a1 1 0 0 0-1-1l-13 3a1 1 0 0 0-1 1v19"
        }
      ),
      /* @__PURE__ */ jsx(
        "path",
        {
          fill: "currentColor",
          fillRule: "evenodd",
          d: "M6.25 10A.75.75 0 0 1 7 9.25h3a.75.75 0 0 1 .75.75v3a.75.75 0 0 1-.75.75H7a.75.75 0 0 1-.75-.75v-3Zm1.5.75v1.5h1.5v-1.5h-1.5ZM6.25 16a.75.75 0 0 1 .75-.75h3a.75.75 0 0 1 .75.75v3a.75.75 0 0 1-.75.75H7a.75.75 0 0 1-.75-.75v-3Zm1.5.75v1.5h1.5v-1.5h-1.5ZM11.25 10a.75.75 0 0 1 .75-.75h3a.75.75 0 0 1 .75.75v3a.75.75 0 0 1-.75.75h-3a.75.75 0 0 1-.75-.75v-3Zm1.5.75v1.5h1.5v-1.5h-1.5ZM11.25 16a.75.75 0 0 1 .75-.75h3a.75.75 0 0 1 .75.75v3a.75.75 0 0 1-.75.75h-3a.75.75 0 0 1-.75-.75v-3Zm1.5.75v1.5h1.5v-1.5h-1.5Z",
          clipRule: "evenodd"
        }
      ),
      /* @__PURE__ */ jsx(
        "path",
        {
          stroke: "currentColor",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 1.5,
          d: "M28 26.998V12l-9-5.5"
        }
      ),
      /* @__PURE__ */ jsx(
        "path",
        {
          fill: "currentColor",
          fillRule: "evenodd",
          d: "M21.25 14a.75.75 0 0 1 .75-.75h3a.75.75 0 0 1 .75.75v3a.75.75 0 0 1-.75.75h-3a.75.75 0 0 1-.75-.75v-3Zm1.5.75v1.5h1.5v-1.5h-1.5ZM21.25 20a.75.75 0 0 1 .75-.75h3a.75.75 0 0 1 .75.75v3a.75.75 0 0 1-.75.75h-3a.75.75 0 0 1-.75-.75v-3Zm1.5.75v1.5h1.5v-1.5h-1.5Z",
          clipRule: "evenodd"
        }
      ),
      /* @__PURE__ */ jsx(
        "path",
        {
          stroke: "currentColor",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 1.5,
          d: "M13 26v-3.5H9V26"
        }
      )
    ]
  }
));
var City2_default = SvgCity2;

export {
  City2_default
};
