"use client";
import {
  withPhosphorWrapper
} from "./chunk-LVROC32W.js";

// src/ExhaustPipe.tsx
import { jsx, jsxs } from "react/jsx-runtime";
var SvgExhaustPipe = withPhosphorWrapper((props) => /* @__PURE__ */ jsxs(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    fill: "none",
    viewBox: "0 0 32 32",
    ...props,
    children: [
      /* @__PURE__ */ jsx(
        "path",
        {
          stroke: "currentColor",
          strokeWidth: 1.5,
          d: "M5.665 22.05 3.19 24.525a.5.5 0 0 0 0 .707l1.415 1.415a.5.5 0 0 0 .707 0l2.475-2.475M18.393 9.322l2.089-2.088a2.5 2.5 0 0 1 3.317-.195l1.346 1.064a.1.1 0 0 0 .133-.008l1.247-1.248a.5.5 0 0 1 .707 0l1.414 1.415a.5.5 0 0 1 0 .707l-1.402 1.402a2.5 2.5 0 0 1-3.317.194l-1.38-1.09a.05.05 0 0 0-.067.003l-1.965 1.966M13.446 24.169l7.069-7.069a2 2 0 0 0 0-2.828l-.319-.318a.05.05 0 0 1 0-.07l1.026-1.026a.5.5 0 0 0 0-.707L17.7 8.629a.5.5 0 0 0-.707 0l-1.025 1.025a.05.05 0 0 1-.071 0l-.332-.332a2 2 0 0 0-2.829 0L5.68 16.38a2 2 0 0 0 0 2.828l.332.332a.05.05 0 0 1 0 .07l-1.039 1.04a.5.5 0 0 0 0 .707l3.522 3.522a.5.5 0 0 0 .707 0l1.026-1.026a.05.05 0 0 1 .07 0l.332.327a2 2 0 0 0 2.817-.011Z"
        }
      ),
      /* @__PURE__ */ jsx(
        "path",
        {
          stroke: "currentColor",
          strokeLinecap: "round",
          strokeWidth: 1.5,
          d: "m16.03 17.614-3.89 3.89"
        }
      )
    ]
  }
));
var ExhaustPipe_default = SvgExhaustPipe;

export {
  ExhaustPipe_default
};
