"use client";
import {
  withPhosphorWrapper
} from "./chunk-LVROC32W.js";

// src/Van.tsx
import { jsx, jsxs } from "react/jsx-runtime";
var SvgVan = withPhosphorWrapper((props) => /* @__PURE__ */ jsxs(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    fill: "none",
    viewBox: "0 0 32 32",
    ...props,
    children: [
      /* @__PURE__ */ jsx(
        "path",
        {
          stroke: "currentColor",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 1.5,
          d: "m27 16-1.749-5.371a1 1 0 0 0-.928-.629H21M23.5 27a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM8.5 27a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM20.5 24h-9"
        }
      ),
      /* @__PURE__ */ jsx(
        "path",
        {
          stroke: "currentColor",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 1.5,
          d: "M5.5 24H3c-.265 0-.52-.092-.707-.256A.823.823 0 0 1 2 23.125v-12.25c0-.232.105-.455.293-.619C2.48 10.092 2.735 10 3 10h19"
        }
      ),
      /* @__PURE__ */ jsx(
        "path",
        {
          stroke: "currentColor",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 1.5,
          d: "M26.5 24H29a1 1 0 0 0 1-1v-7H20v-6"
        }
      )
    ]
  }
));
var Van_default = SvgVan;

export {
  Van_default
};
