"use client";
import {
  withPhosphorWrapper
} from "./chunk-LVROC32W.js";

// src/Lawnmower.tsx
import { jsx, jsxs } from "react/jsx-runtime";
var SvgLawnmower = withPhosphorWrapper((props) => /* @__PURE__ */ jsxs(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    fill: "none",
    viewBox: "0 0 32 32",
    ...props,
    children: [
      /* @__PURE__ */ jsx(
        "path",
        {
          stroke: "currentColor",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 1.5,
          d: "M6 18h20M21 18H11v-4h10v4ZM7.837 17.82 2.5 7l2-2.5"
        }
      ),
      /* @__PURE__ */ jsx(
        "path",
        {
          stroke: "currentColor",
          strokeMiterlimit: 10,
          strokeWidth: 1.5,
          d: "M26 28a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM6 28a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
        }
      ),
      /* @__PURE__ */ jsx(
        "path",
        {
          stroke: "currentColor",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 1.5,
          d: "M23 25H9M5 21.813v-2.625c0-.315.147-.617.41-.84.263-.223.619-.348.99-.348M27 21.813v-2.625c0-.315-.148-.617-.41-.84A1.536 1.536 0 0 0 25.6 18"
        }
      )
    ]
  }
));
var Lawnmower_default = SvgLawnmower;

export {
  Lawnmower_default
};
