"use client";
import {
  withPhosphorWrapper
} from "./chunk-LVROC32W.js";

// src/Beach.tsx
import { jsx, jsxs } from "react/jsx-runtime";
var SvgBeach = withPhosphorWrapper((props) => /* @__PURE__ */ jsxs(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    fill: "none",
    viewBox: "0 0 32 32",
    ...props,
    children: [
      /* @__PURE__ */ jsx(
        "path",
        {
          stroke: "currentColor",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 1.5,
          d: "M8 10.5s1.062 2.21 1.5 4c.438 1.79.5 7 .5 7M1.999 27S6.125 21.5 12 21.5c5.874 0 10.5 5.5 10.5 5.5M4.5 16.5s-.091-1.755.5-3.5c.591-1.744 3-2.5 3-2.5M15 12s-1-1-3-1.5-4 0-4 0M1.204 11.442s1.283-1.2 2.998-1.873c1.715-.672 3.845.682 3.845.682M24 11a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM22 22h6.626M18.5 19H30M28 8h1.414M18.5 8h1.414M27 5l1-1M20 12l1-1M28 12l-1-1M21 5l-1-1M24 13.414V12M24 3.9V2.486M22.5 27h5"
        }
      ),
      /* @__PURE__ */ jsx(
        "path",
        {
          stroke: "currentColor",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 1.5,
          d: "M7.607 10.155s.91-1.503 2.38-2.614c1.47-1.11 3.886-.382 3.886-.382"
        }
      )
    ]
  }
));
var Beach_default = SvgBeach;

export {
  Beach_default
};
