"use client";
import {
  withPhosphorWrapper
} from "./chunk-LVROC32W.js";

// src/Car.tsx
import { jsx, jsxs } from "react/jsx-runtime";
var SvgCar = withPhosphorWrapper((props) => /* @__PURE__ */ jsxs(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    fill: "none",
    viewBox: "0 0 32 32",
    ...props,
    children: [
      /* @__PURE__ */ jsx(
        "path",
        {
          stroke: "currentColor",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 1.5,
          d: "M26.5 21h3c.5 0 2-7-.5-7H10l3-5"
        }
      ),
      /* @__PURE__ */ jsx(
        "path",
        {
          stroke: "currentColor",
          strokeMiterlimit: 10,
          strokeWidth: 1.5,
          d: "M23.5 24a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM8.5 24a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
        }
      ),
      /* @__PURE__ */ jsx(
        "path",
        {
          stroke: "currentColor",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 1.5,
          d: "M20 21h-8M5.5 21H3c-.265 0-.707 0-.707-.293v-4.939S2.5 14 3.5 13.5L10 9h10l5.5 5M17 14V9"
        }
      )
    ]
  }
));
var Car_default = SvgCar;

export {
  Car_default
};
