"use client";
import {
  withPhosphorWrapper
} from "./chunk-LVROC32W.js";

// src/Metsahallitus.tsx
import { jsx } from "react/jsx-runtime";
var SvgMetsahallitus = withPhosphorWrapper(
  (props) => /* @__PURE__ */ jsx(
    "svg",
    {
      xmlns: "http://www.w3.org/2000/svg",
      width: "1em",
      height: "1em",
      fill: "none",
      viewBox: "0 0 32 32",
      ...props,
      children: /* @__PURE__ */ jsx(
        "path",
        {
          fill: "currentColor",
          fillRule: "evenodd",
          d: "M22 13.15V14H9.5V4.38l5.762 4.94c.28.24.695.24.976 0L22 4.38v8.77ZM8.75 15.5h14v-.75.75a.75.75 0 0 0 .75-.75v-12a.75.75 0 0 0-1.238-.57L15.75 7.763 9.238 2.181A.75.75 0 0 0 8 2.75v12a.75.75 0 0 0 .75.75ZM22 19.35v-.85H9.5v9.62l5.762-4.94a.75.75 0 0 1 .976 0L22 28.12v-8.77ZM8.75 17h14v.75V17a.75.75 0 0 1 .75.75v12a.75.75 0 0 1-1.238.57l-6.512-5.582-6.512 5.582A.75.75 0 0 1 8 29.75v-12a.75.75 0 0 1 .75-.75Z",
          clipRule: "evenodd"
        }
      )
    }
  )
);
var Metsahallitus_default = SvgMetsahallitus;

export {
  Metsahallitus_default
};
