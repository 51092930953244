"use client";
import {
  withPhosphorWrapper
} from "./chunk-LVROC32W.js";

// src/City.tsx
import { jsx, jsxs } from "react/jsx-runtime";
var SvgCity = withPhosphorWrapper((props) => /* @__PURE__ */ jsxs(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    fill: "none",
    viewBox: "0 0 32 32",
    ...props,
    children: [
      /* @__PURE__ */ jsx(
        "path",
        {
          stroke: "currentColor",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 1.5,
          d: "M19 28V7.875a.824.824 0 0 0-.293-.619A1.077 1.077 0 0 0 18 7H6c-.265 0-.52.092-.707.256A.824.824 0 0 0 5 7.875V28M2 28h28"
        }
      ),
      /* @__PURE__ */ jsx(
        "path",
        {
          stroke: "currentColor",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 1.5,
          d: "M14 28v-5h-4v5"
        }
      ),
      /* @__PURE__ */ jsx(
        "path",
        {
          fill: "currentColor",
          fillRule: "evenodd",
          d: "M7.25 10A.75.75 0 0 1 8 9.25h3a.75.75 0 0 1 .75.75v3a.75.75 0 0 1-.75.75H8a.75.75 0 0 1-.75-.75v-3Zm1.5.75v1.5h1.5v-1.5h-1.5ZM7.25 16a.75.75 0 0 1 .75-.75h3a.75.75 0 0 1 .75.75v3a.75.75 0 0 1-.75.75H8a.75.75 0 0 1-.75-.75v-3Zm1.5.75v1.5h1.5v-1.5h-1.5ZM12.25 10a.75.75 0 0 1 .75-.75h3a.75.75 0 0 1 .75.75v3a.75.75 0 0 1-.75.75h-3a.75.75 0 0 1-.75-.75v-3Zm1.5.75v1.5h1.5v-1.5h-1.5ZM12.25 16a.75.75 0 0 1 .75-.75h3a.75.75 0 0 1 .75.75v3a.75.75 0 0 1-.75.75h-3a.75.75 0 0 1-.75-.75v-3Zm1.5.75v1.5h1.5v-1.5h-1.5Z",
          clipRule: "evenodd"
        }
      ),
      /* @__PURE__ */ jsx(
        "path",
        {
          stroke: "currentColor",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 1.5,
          d: "M20 7V4.125c0-.033-.12-.065-.335-.088A7.907 7.907 0 0 0 18.857 4H5.143c-.303 0-.594.013-.808.037C4.12 4.06 4 4.092 4 4.125V7M27 27.998v-15a1 1 0 0 0-1-1h-7M4 7h16M23 12V8M22 18l2-.001M22 15l2-.001"
        }
      )
    ]
  }
));
var City_default = SvgCity;

export {
  City_default
};
