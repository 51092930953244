"use client";
import {
  withPhosphorWrapper
} from "./chunk-LVROC32W.js";

// src/WoodPlank.tsx
import { jsx, jsxs } from "react/jsx-runtime";
var SvgWoodPlank = withPhosphorWrapper((props) => /* @__PURE__ */ jsxs(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    fill: "none",
    viewBox: "0 0 32 32",
    ...props,
    children: [
      /* @__PURE__ */ jsx(
        "path",
        {
          stroke: "currentColor",
          strokeMiterlimit: 10,
          strokeWidth: 1.5,
          d: "M14.31 22.64H2.86a.1.1 0 0 0-.1.1v4.54a.1.1 0 0 0 .1.1h11.45a.1.1 0 0 0 .1-.1v-4.54a.1.1 0 0 0-.1-.1ZM2.82 22.64 20.74 4.66s.05-.03.08-.03h8.3c.05 0 .08.06.05.1L14.41 22.64"
        }
      ),
      /* @__PURE__ */ jsx(
        "path",
        {
          stroke: "currentColor",
          strokeLinecap: "round",
          strokeMiterlimit: 10,
          strokeWidth: 1.5,
          d: "M14.44 27.31 29.22 9.33s.02-.04.02-.06V4.69"
        }
      )
    ]
  }
));
var WoodPlank_default = SvgWoodPlank;

export {
  WoodPlank_default
};
