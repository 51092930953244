"use client";
import {
  withPhosphorWrapper
} from "./chunk-LVROC32W.js";

// src/Forklift.tsx
import { jsx, jsxs } from "react/jsx-runtime";
var SvgForklift = withPhosphorWrapper((props) => /* @__PURE__ */ jsxs(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    fill: "none",
    viewBox: "0 0 32 32",
    ...props,
    children: [
      /* @__PURE__ */ jsx(
        "path",
        {
          stroke: "currentColor",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 1.5,
          d: "M18.5 27a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5ZM21 15l-3.108-7.77L17 5H8v10H3v9.5h2m5.5 0H16"
        }
      ),
      /* @__PURE__ */ jsx(
        "path",
        {
          stroke: "currentColor",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 1.5,
          d: "M6 15h8.5l1.5 2h5v6.5M25 25V4.5M21 15v2M24.5 18H21M24.5 21H21M7.5 27a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5ZM25 25h5"
        }
      )
    ]
  }
));
var Forklift_default = SvgForklift;

export {
  Forklift_default
};
