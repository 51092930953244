"use client";
import {
  withPhosphorWrapper
} from "./chunk-LVROC32W.js";

// src/Finland.tsx
import { jsx } from "react/jsx-runtime";
var SvgFinland = withPhosphorWrapper((props) => /* @__PURE__ */ jsx(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    fill: "none",
    viewBox: "0 0 32 32",
    ...props,
    children: /* @__PURE__ */ jsx(
      "path",
      {
        stroke: "currentColor",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: 1.5,
        d: "M10 22.227c0-1.307 5.107-4.547 5.417-6.227.31-1.68-.495-1.024-.813-1.68l-.812-1.68V8.16L11.083 4.8l1.084-1.12 2.166 1.68h1.625l.542-2.24L18.667 2l1.625 1.68-.542 1.12-.542 1.68.542 1.12 1.083 1.12-.541 2.24 1.083 3.36-.542 1.68 1.084 1.68-.542 2.24 1.625.56v1.68s-.274 1.877-1.045 3.08c-.77 1.203-2.901 3.08-2.901 3.08H17.97L12.167 30l-1.625-.56L10 27.2l.542-2.24S10 23.533 10 22.227ZM6 29.44s.408-.433.417-.44l-.125-.24v-.32l-.209-.24.084-.08.166.12h.125l.042-.16.167-.08.125.12-.042.08-.042.12.042.08.083.08-.041.16.083.24-.042.12.084.12-.042.16.125.04v.12s-.021.134-.08.22c-.06.086-.224.22-.224.22h-.083l-.446.12-.125-.04L6 29.8l.042-.16-.042-.2Z"
      }
    )
  }
));
var Finland_default = SvgFinland;

export {
  Finland_default
};
