"use client";
import {
  withPhosphorWrapper
} from "./chunk-LVROC32W.js";

// src/Puolustusvoimat.tsx
import { jsx } from "react/jsx-runtime";
var SvgPuolustusvoimat = withPhosphorWrapper(
  (props) => /* @__PURE__ */ jsx(
    "svg",
    {
      xmlns: "http://www.w3.org/2000/svg",
      width: "1em",
      height: "1em",
      fill: "none",
      viewBox: "0 0 32 32",
      ...props,
      children: /* @__PURE__ */ jsx(
        "path",
        {
          stroke: "currentColor",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 1.5,
          d: "M5.564 29v-6.933h2.82v-9.978h-2.82L5 3h3.385v2.311h5.076V3h5.077v2.311h5.077V3H27l-.564 9.089h-2.82v9.978h2.82V29H5.564Z"
        }
      )
    }
  )
);
var Puolustusvoimat_default = SvgPuolustusvoimat;

export {
  Puolustusvoimat_default
};
