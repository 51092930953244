"use client";
import {
  withPhosphorWrapper
} from "./chunk-LVROC32W.js";

// src/Scale.tsx
import { jsx, jsxs } from "react/jsx-runtime";
var SvgScale = withPhosphorWrapper((props) => /* @__PURE__ */ jsxs(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    fill: "none",
    viewBox: "0 0 32 32",
    ...props,
    children: [
      /* @__PURE__ */ jsx(
        "path",
        {
          stroke: "currentColor",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 1.5,
          d: "M1.5 16 6 6l4.5 10S10 20.5 6 20.5 1.5 16 1.5 16ZM21 16l4.5-10L30 16s-.5 4.5-4.5 4.5S21 16 21 16ZM4 5h9M19 5h9"
        }
      ),
      /* @__PURE__ */ jsx(
        "path",
        {
          stroke: "currentColor",
          strokeMiterlimit: 10,
          strokeWidth: 1.5,
          d: "M16 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
        }
      ),
      /* @__PURE__ */ jsx(
        "path",
        {
          stroke: "currentColor",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 1.5,
          d: "M16 8v20M12 28h8M22 16h8M2 16h8"
        }
      )
    ]
  }
));
var Scale_default = SvgScale;

export {
  Scale_default
};
