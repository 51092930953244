"use client";
import {
  withPhosphorWrapper
} from "./chunk-LVROC32W.js";

// src/Map.tsx
import { jsx, jsxs } from "react/jsx-runtime";
var SvgMap = withPhosphorWrapper((props) => /* @__PURE__ */ jsxs(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    fill: "none",
    viewBox: "0 0 32 32",
    ...props,
    children: [
      /* @__PURE__ */ jsx(
        "path",
        {
          stroke: "currentColor",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 1.5,
          d: "M13.5 7 6 6.998v20h20v-20h-3.5"
        }
      ),
      /* @__PURE__ */ jsx(
        "path",
        {
          stroke: "currentColor",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 1.5,
          d: "M18 15.5S12.221 9.325 14 6c.85-1.587 2.633-2.5 4-2.5s3.15.913 4 2.5c1.78 3.325-4 9.5-4 9.5ZM15 12.5 6.5 27M26 25l-14-7.581"
        }
      ),
      /* @__PURE__ */ jsx("circle", { cx: 18, cy: 8, r: 0.5, fill: "currentColor", stroke: "currentColor" })
    ]
  }
));
var Map_default = SvgMap;

export {
  Map_default
};
