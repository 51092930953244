"use client";
import {
  withPhosphorWrapper
} from "./chunk-LVROC32W.js";

// src/Chair.tsx
import { jsx, jsxs } from "react/jsx-runtime";
var SvgChair = withPhosphorWrapper((props) => /* @__PURE__ */ jsxs(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    fill: "none",
    viewBox: "0 0 32 32",
    ...props,
    children: [
      /* @__PURE__ */ jsx(
        "path",
        {
          stroke: "currentColor",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 1.5,
          d: "M16 16v2.5M5 21h2M11 29.5a6.292 6.292 0 0 1 5-2.5c1.953 0 3.968.842 5 2.5"
        }
      ),
      /* @__PURE__ */ jsx(
        "path",
        {
          stroke: "currentColor",
          strokeWidth: 1.5,
          d: "M9.684 4.74a2 2 0 0 1 1.986-1.77h8.16a2 2 0 0 1 1.977 1.695l1.247 8.07a2 2 0 0 1-1.977 2.305H10.735a2 2 0 0 1-1.987-2.23l.936-8.07Z"
        }
      ),
      /* @__PURE__ */ jsx(
        "path",
        {
          stroke: "currentColor",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 1.5,
          d: "M16 26v-3"
        }
      ),
      /* @__PURE__ */ jsx(
        "path",
        {
          fill: "currentColor",
          fillRule: "evenodd",
          d: "M7.25 19a.75.75 0 0 1 .75-.75h16a.75.75 0 0 1 .75.75v4a.75.75 0 0 1-.75.75H8a.75.75 0 0 1-.75-.75v-4Zm1.5.75v2.5h14.5v-2.5H8.75Z",
          clipRule: "evenodd"
        }
      ),
      /* @__PURE__ */ jsx(
        "path",
        {
          stroke: "currentColor",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 1.5,
          d: "M27 21h-3M27 21v-6M5 21v-6M10 30l.866.5M21.866 30l-.866.5"
        }
      )
    ]
  }
));
var Chair_default = SvgChair;

export {
  Chair_default
};
