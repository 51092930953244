"use client";
import {
  withPhosphorWrapper
} from "./chunk-LVROC32W.js";

// src/BarchartDown.tsx
import { jsx, jsxs } from "react/jsx-runtime";
var SvgBarchartDown = withPhosphorWrapper(
  (props) => /* @__PURE__ */ jsxs(
    "svg",
    {
      xmlns: "http://www.w3.org/2000/svg",
      width: "1em",
      height: "1em",
      fill: "none",
      viewBox: "0 0 32 32",
      ...props,
      children: [
        /* @__PURE__ */ jsx(
          "path",
          {
            stroke: "currentColor",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: 1.5,
            d: "M28 26H4V6"
          }
        ),
        /* @__PURE__ */ jsx(
          "path",
          {
            stroke: "currentColor",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: 1.5,
            d: "m24 20-8-8-4 4-8-8"
          }
        ),
        /* @__PURE__ */ jsx(
          "path",
          {
            stroke: "currentColor",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: 1.5,
            d: "M24 15v5h-5"
          }
        )
      ]
    }
  )
);
var BarchartDown_default = SvgBarchartDown;

export {
  BarchartDown_default
};
