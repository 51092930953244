"use client";
import {
  withPhosphorWrapper
} from "./chunk-LVROC32W.js";

// src/Snowmobile.tsx
import { jsx } from "react/jsx-runtime";
var SvgSnowmobile = withPhosphorWrapper((props) => /* @__PURE__ */ jsx(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    fill: "none",
    viewBox: "0 0 32 32",
    ...props,
    children: /* @__PURE__ */ jsx(
      "path",
      {
        stroke: "currentColor",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: 1.5,
        d: "M16 20h7c3.273 0 4.996-1.509 4-3.5-.5-1-3.5-4.5-3.5-4.5l-4-5h-3l1 6H7M23 20h-9.5M5 20a1 1 0 0 1-1-1l3-6-2-3.5M5 20l-2.5 4.5H11l2.5-4.5M5 20h8.5M25 23.5 23 20M19.031 10.75 15 10.732M16 24.5h12s1.818-.5 2.5-2c.682-1.5-.5-3-.5-3"
      }
    )
  }
));
var Snowmobile_default = SvgSnowmobile;

export {
  Snowmobile_default
};
