"use client";
import {
  withPhosphorWrapper
} from "./chunk-LVROC32W.js";

// src/Tractor.tsx
import { jsx, jsxs } from "react/jsx-runtime";
var SvgTractor = withPhosphorWrapper((props) => /* @__PURE__ */ jsxs(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    fill: "none",
    viewBox: "0 0 32 32",
    ...props,
    children: [
      /* @__PURE__ */ jsx(
        "path",
        {
          stroke: "currentColor",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 1.5,
          d: "M27.5 30a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7ZM7 30a5 5 0 1 0 0-10 5 5 0 0 0 0 10Z"
        }
      ),
      /* @__PURE__ */ jsx(
        "path",
        {
          stroke: "currentColor",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 1.5,
          d: "M15 25a8 8 0 0 0-13.5-5.81M24 25h-9"
        }
      ),
      /* @__PURE__ */ jsx(
        "path",
        {
          stroke: "currentColor",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 1.5,
          d: "M7 17H4.8a.844.844 0 0 1-.566-.22.727.727 0 0 1-.234-.53v-2.5L5.971 12V4H9.5m5 0 3 9.75M14.5 4H17m-2.5 0h-5m0 0v8M15 25a8 8 0 0 0-8-8"
        }
      ),
      /* @__PURE__ */ jsx(
        "path",
        {
          stroke: "currentColor",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 1.5,
          d: "M6 12h6.5l1.5 2h14v3.5l1.5 1.5v4M23.862 13.02V7.435L22.5 6.5M19 17v1M22 17v1M25 17v1"
        }
      )
    ]
  }
));
var Tractor_default = SvgTractor;

export {
  Tractor_default
};
