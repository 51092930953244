"use client";
import {
  withPhosphorWrapper
} from "./chunk-LVROC32W.js";

// src/HeavyEquipment.tsx
import { jsx, jsxs } from "react/jsx-runtime";
var SvgHeavyEquipment = withPhosphorWrapper(
  (props) => /* @__PURE__ */ jsxs(
    "svg",
    {
      xmlns: "http://www.w3.org/2000/svg",
      width: "1em",
      height: "1em",
      fill: "none",
      viewBox: "0 0 32 32",
      ...props,
      children: [
        /* @__PURE__ */ jsxs(
          "g",
          {
            stroke: "currentColor",
            strokeLinecap: "round",
            strokeLinejoin: "round",
            strokeWidth: 1.5,
            clipPath: "url(#heavy_equipment_svg__a)",
            children: [
              /* @__PURE__ */ jsx("path", { d: "M31 16h-6v-5h4.286c.15 0 .296.06.42.172a1 1 0 0 1 .276.457L31 16ZM25.5 26.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5ZM12.5 26.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5ZM5.5 26.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5ZM23 24h-8M10 24H8" }),
              /* @__PURE__ */ jsx("path", { d: "M2.5 24H2a1 1 0 0 1-1-1V11a1 1 0 0 1 1-1h23v11.402M25 20.69V16h6v7.111a.979.979 0 0 1-.22.629.696.696 0 0 1-.53.26h-1.875M1 14h18M1 18h18" })
            ]
          }
        ),
        /* @__PURE__ */ jsx("defs", { children: /* @__PURE__ */ jsx("clipPath", { id: "heavy_equipment_svg__a", children: /* @__PURE__ */ jsx("path", { fill: "#fff", d: "M0 0h32v32H0z" }) }) })
      ]
    }
  )
);
var HeavyEquipment_default = SvgHeavyEquipment;

export {
  HeavyEquipment_default
};
