"use client";
import {
  withPhosphorWrapper
} from "./chunk-LVROC32W.js";

// src/Truck.tsx
import { jsx, jsxs } from "react/jsx-runtime";
var SvgTruck = withPhosphorWrapper((props) => /* @__PURE__ */ jsxs(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    fill: "none",
    viewBox: "0 0 32 32",
    ...props,
    children: [
      /* @__PURE__ */ jsx(
        "path",
        {
          stroke: "currentColor",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 1.5,
          d: "M30 15h-8v-5h5.323a1 1 0 0 1 .928.629L30 15ZM2 18h20M23.5 27a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM8.5 27a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM20.5 24h-9"
        }
      ),
      /* @__PURE__ */ jsx(
        "path",
        {
          stroke: "currentColor",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: 1.5,
          d: "M5.5 24H3a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h19v13.402M22 21.402V15h8v8a1 1 0 0 1-1 1h-2.5"
        }
      )
    ]
  }
));
var Truck_default = SvgTruck;

export {
  Truck_default
};
