"use client";
import {
  withPhosphorWrapper
} from "./chunk-LVROC32W.js";

// src/Grill.tsx
import { jsx } from "react/jsx-runtime";
var SvgGrill = withPhosphorWrapper((props) => /* @__PURE__ */ jsx(
  "svg",
  {
    xmlns: "http://www.w3.org/2000/svg",
    width: "1em",
    height: "1em",
    fill: "none",
    viewBox: "0 0 32 32",
    ...props,
    children: /* @__PURE__ */ jsx(
      "path",
      {
        stroke: "currentColor",
        strokeMiterlimit: 22.93,
        strokeWidth: 1.5,
        d: "M14.703 28.264H5.856m8.847 0c0 .7.638 1.28 1.428 1.28.79 0 1.433-.573 1.433-1.28 0-.708-.642-1.283-1.433-1.283h.004c-.79 0-1.432.583-1.432 1.283Zm1.044-1.236-1.282-4.282M5.283 30l2.172-7.254m.52-10.497c-1.343-2.458.847-2.332.847-3.832s-2.19-1.373-.848-3.831m3.361 7.663c-1.342-2.458.847-2.332.847-3.832s-2.189-1.373-.847-3.831m3.36 7.663c-1.341-2.458.848-2.332.848-3.832s-2.19-1.373-.847-3.831m11.96 6.545c.383.072 1.297.199 1.473.207 2.036.09 2.634-3.42.664-3.874a21.474 21.474 0 0 0-1.473-.196l-.664 3.863Zm-5.69 3.482c7.085.375 8.978-10.668 2.09-12.204A29.398 29.398 0 0 0 19.945 2l-2.079 12.17c1.025.201 1.777.379 3.103.443h-.004Zm-16.865-.444c.138 2.44.517 5.537 1.12 6.519 2.439 3.984 9.086 3.99 11.523 0 .602-.982.983-4.08 1.119-6.519H4.101Z"
      }
    )
  }
));
var Grill_default = SvgGrill;

export {
  Grill_default
};
